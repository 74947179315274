* {
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #4521a0 !important;
  border-radius: 6px !important;
}

::-webkit-scrollbar-track {
  background: #222;
}

a {
  text-decoration: none;
}

.ql-snow .ql-picker {
  color: #fff !important;
}

.ql-snow .ql-stroke {
  stroke: #fff !important;
}

.ql-toolbar.ql-snow {
  border-radius: 0.5rem;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #fff !important;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background-color: #3d3d3d !important;
}

.scrollDiv {
  background: #7150d0;
  overflow-x: hidden;
}

.scrollText {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  font-weight: 400;
  animation: scroll 10s linear infinite;
}

.star {
  width: 30px;
  height: auto;
  margin: 0 0.5rem;
}

.scrollDiv:hover .scrollText {
  cursor: pointer;
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.slick-slide.slick-active.slick-center.slick-current img {
  background: #b095ff;
  border-radius: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.2) !important;
  padding: 1rem;
}

.slick-initialized .slick-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  transform: scale(0.8) !important;
}

@media screen and (max-width: 600px) {
  .scrollText {
    font-size: 0.6rem;
  }

  .star {
    width: 10px;
    height: auto;
    margin: 0 0.2rem;
  }

  .slick-initialized .slick-slide {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transform: scale(1) !important;
  }
}

.bannerImg {
  width: 80%;
  height: auto;
}

.ql-editor.ql-blank::before {
  color: #fff !important;
}
